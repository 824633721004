<template lang="pug">
NuxtImg(
  :src="imageUrl"
  :alt="imageAlt"
  loading="lazy"
)
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { TImage } from "@/models/image";

interface IProps {
  image?: TImage | null;
  format?: "large" | "medium" | "small" | "thumbnail";
}

const props = withDefaults(defineProps<IProps>(), {
  image: null,
  format: "medium",
});

const isLocalhost = computed(() => {
  // return false;
  
  if (typeof window !== 'undefined') {
    const { host } = window.location;
    return host.includes("localhost");
  }

  return false;
})

const imageAlt = computed(() => {
  return props.image?.alternativeText || "";
})

const imageUrl = computed(() => {
  if (isLocalhost.value) {
    return props.image?.hash && props.image?.ext
      ? `http://s3.nationbetter.uk.s3.eu-west-2.amazonaws.com/${props.image.hash}${props.image.ext}`
      : '';
  }

  const originalUrl = props.image?.url;

  if (originalUrl) return originalUrl;
  
  const formats = props.image?.formats;

  const formatUrl =
    formats?.large?.url ||
    formats?.medium?.url ||
    formats?.small?.url ||
    formats?.thumbnail?.url ||
    '';

  const url = props.image?.url || '';

  return formatUrl ? formatUrl : url;
});
</script>